<div class="modal-wrapper p-4" [formGroup]="projectForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Project" : "Add Project" }}
      </h2>
      <p class="textXS mt-2 text">
        {{
          isEditMode
            ? "Enter details for edit project"
            : "Enter details for add project"
        }}
      </p>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Cost Center <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="cost_centerID">
          <mat-option
            *ngFor="let cost of costList"
            [value]="cost.cc_cost_centerID"
            >{{ cost.cc_center_name }} | {{ cost.cc_code.toUpperCase() }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Project Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="name"
        />
      </mat-form-field>
      <div
        *ngIf="
          projectForm.get('name')?.touched || projectForm.get('name')?.dirty
        "
      >
        <div
          *ngIf="projectForm.get('name')?.hasError('whitespace')"
          class="required-star mt-1"
        >
          Field cannot be empty or contain only whitespace.
        </div>
        <div
          *ngIf="
            !projectForm.get('name')?.hasError('whitespace') &&
            projectForm.get('name')?.hasError('invalidNameSpecialChar')
          "
          class="required-star mt-1"
        >
          Field cannot contain special characters.
        </div>
        <div
          *ngIf="
            !projectForm.get('name')?.hasError('whitespace') &&
            projectForm.get('name')?.hasError('invalidName')
          "
          class="required-star mt-1"
        >
          Invalid Name.
        </div>
      </div>
    </div>
    <div>
      <div class="mb-5 mt-5">
        <span class="mat-body-1 text mb-1 input-label">Project Cost Model</span>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="costModal"
          (change)="onTypeChange()"
        >
          <mat-radio-button value="fixed" [checked]="true"
            >Fixed</mat-radio-button
          >
          <mat-radio-button value="hourly" [checked]="false"
            >Hourly</mat-radio-button
          >
          <mat-radio-button value="daily" [checked]="false"
            >Daily</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>
    <div
      class="col s12 m6 l6 mb-4"
      *ngIf="
        projectForm.get('costModal')?.value === 'fixed' && !isMilestoneBased
      "
    >
      <p class="mat-body-1 text mb-1 input-label">
        Fixed Cost {{ currency ? " (" + currency + ")" : "" }}
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          matInput
          placeholder="Enter here"
          formControlName="fixedCost"
          appNumericInput
          (numericInputValidityChange)="
            handleNumericInputValidityChange('fixedCost', $event)
          "
        />
      </mat-form-field>
      <span class="required-star" *ngIf="!numericInputValidity['fixedCost']"
        >Please enter a numeric value.</span
      >
    </div>
    <div class="switch-wrapper mb-4">
      <sft-switch-button
        *ngIf="projectForm.get('costModal')?.value === 'fixed'"
        [label]="'Is this Project Milestone-Based?'"
        [disable]="false"
        [checked]="isMilestoneBased"
        [color]="'primary'"
        [disableRipple]="false"
        [indeterminate]="true"
        [labelPosition]="'before'"
        (click)="toggleMilestoneBased()"
      ></sft-switch-button>
    </div>

    <ng-container formArrayName="milestones">
      <div
        class="mb-4"
        *ngIf="
          isMilestoneBased && projectForm.get('costModal')?.value === 'fixed'
        "
      >
        <div *ngIf="milestonesFormArray">
          <div
            *ngFor="
              let milestone of milestonesFormArray.controls;
              let i = index;
              let last = last
            "
            [formGroupName]="i"
            class="grey-bg p-2 mb-2"
          >
            <div class="d-flex justify-between">
              <span class="mat-body-1">Milestone {{ i + 1 }}</span>
              <mat-icon
                class="pointer"
                *ngIf="milestonesFormArray.length > 1"
                (click)="removeMilestone(i)"
                >close</mat-icon
              >
            </div>
            <div class="row mt-2">
              <div class="col s12 m6 l5">
                <p class="mat-body-1 text mb-1 input-label">
                  Name <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="mileStoneName"
                  />
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    milestone.get('mileStoneName')?.invalid &&
                    (milestone.get('mileStoneName')?.touched ||
                      milestone.get('mileStoneName')?.dirty)
                  "
                >
                  Field is required.
                </span>
              </div>

              <div class="col s12 m6 l3">
                <p class="mat-body-1 text mb-1 input-label">
                  Price<span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="price"
                    appNumericInput
                    (numericInputValidityChange)="
                      handleNumericInputValidityChange('price', $event)
                    "
                  />
                </mat-form-field>

                <span
                  class="required-star"
                  *ngIf="!numericInputValidity['price']"
                  >Please enter a numeric value.</span
                >
              </div>

              <div class="col s12 m6 l4">
                <div class="input-wrapper">
                  <p class="mat-body-1 text mb-1 input-label">
                    Due Date <span class="required-star">*</span>
                  </p>
                  <mat-form-field
                    appearance="outline"
                    subscriptSizing="dynamic"
                  >
                    <input
                      matInput
                      [matDatepicker]="endDatePicker"
                      placeholder="dd/mm/yyyy"
                      formControlName="dueDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="endDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="!last" style="height: 20px"></div>
                  <span
                    class="required-star"
                    *ngIf="
                      milestone.get('dueDate')?.invalid &&
                      (milestone.get('dueDate')?.touched ||
                        milestone.get('dueDate')?.dirty)
                    "
                  >
                    Field is required.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 add-button-wrapper">
          <sft-button
            [label]="' Add Milestone'"
            [color]="'accent'"
            [variant]="'default'"
            [disable]="false"
            [withIcon]="true"
            [icon]="'add'"
            [disableRipple]="false"
            [size]="'small'"
            (click)="addMilestone()"
          ></sft-button>
        </div>
      </div>
    </ng-container>

    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Project Status <span class="required-star">*</span>
      </p>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        placeholder="select"
      >
        <mat-select placeholder="Active" formControlName="status">
          <mat-option value="active">Active</mat-option>
          <mat-option value="hold">Hold</mat-option>
          <mat-option value="completed">Completed</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col s12 m6 l6">
        <div class="input-wrapper">
          <p class="mat-body-1 text mb-1 input-label">
            Start Date <span class="required-star">*</span>
          </p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              [matDatepicker]="startDatePicker"
              placeholder="dd/mm/yyyy"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="
              projectForm.get('startDate')?.invalid &&
              (projectForm.get('startDate')?.touched ||
                projectForm.get('startDate')?.dirty)
            "
          >
            Field is required.
          </span>
        </div>
      </div>

      <div class="col s12 m6 l6">
        <div class="input-wrapper">
          <p class="mat-body-1 text mb-1 input-label">End Date</p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              [matDatepicker]="endDatePicker"
              placeholder="dd/mm/yyyy"
              formControlName="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="projectForm.get('endDate')?.errors?.['reviewDateInvalid']"
          >
            End Date must be greater than Start Date.</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSubmit()"
    ></sft-button>
  </div>
</div>
