import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { ExpenseService } from 'src/app/admin-portal/modules/expense-detail/services/expense.service';
import { nameValidator } from 'src/app/utils/name-validation.utils';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-expense-detail',
  templateUrl: './expense-dialog.component.html',
})
export class ExpenseDialogComponent {
  expenseForm!: FormGroup;
  expenseList: any[] = [];
  id!: string;
  isButtonDisabled: boolean = true;
  isSubmitting: boolean = false;
  isEditMode: boolean = false;
  currency: string | undefined;
  selectedCostCentreId: string | undefined;
  selectedAccount: any;
  costList: any[] = [];

  constructor(
    public matDialog: MatDialogRef<ExpenseDialogComponent>,
    private formBuilder: FormBuilder,
    private expenseService: ExpenseService,
    private globalService: GlobalService,
    private costCenterService: CostCenterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  private unsubscribe: Subject<void> = new Subject();
  @Output() expenseEdited: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.isEditMode = this.data.isEditMode;

    this.initializeForm();
    this.expenseForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.expenseForm.invalid || !this.expenseForm.dirty;
    });

    if (this.isEditMode && this.data.element) {
      this.patchFormValues(this.data.element);
    }
    this.loadCostCenters().subscribe();

    this.isButtonDisabled = true;
    this.currency = this.globalService.getCurrency();
  }

  onTypeChange() {
    this.expenseForm.value;
  }

  logSelectedCostCentre(selectedCostCentreId: string): void {
    this.selectedCostCentreId = selectedCostCentreId;
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListByAccountId(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        this.data.accountID
      )
      .pipe(
        tap((response: any) => {
          this.costList = response.data.records;
        })
      );
  }

  patchFormValues(element: any) {
    this.expenseForm.patchValue({
      expenseID: element.roe_expenseID,
      name: element.roe_name,
      cost_centerID: element.roe_cost_centerID,
      quantity: element.roe_quantity,
      type: element.roe_type,
      expenseAmount: element.roe_expense_amount,
    });
  }

  initializeForm(initialType: string = 'one_time') {
    this.expenseForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator(), nameValidator]],
      cost_centerID: ['', Validators.required],
      type: [initialType, Validators.required],
      quantity: ['', Validators.required],
      expenseAmount: ['', Validators.required],
      expenseID: [''],
      accountID: [''],
    });

    this.expenseForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.expenseForm.invalid || !this.expenseForm.dirty;
    });

    this.isButtonDisabled = true;
  }

  onSubmit(): void {
    if (this.expenseForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      const { name, type, cost_centerID, quantity, expenseAmount } =
        this.expenseForm.value;
      const expenseID = this.expenseForm.get('expenseID')?.value;
      const accountID = this.data.accountID;

      // If in edit mode, update the expense
      if (this.isEditMode) {
        const updatedExpenseData = {
          name,
          type,
          cost_centerID,
          quantity,
          expenseAmount,
          expenseID,
        };

        this.expenseService
          .updateExpense(updatedExpenseData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              console.error('Error updating expense:', error);
              this.isSubmitting = false;
            },
          });
      } else {
        // If not in edit mode, create a expense
        const newExpenseData = {
          name,
          type,
          cost_centerID,
          quantity,
          expenseAmount,
          accountID,
        };

        this.expenseService
          .createExpense(newExpenseData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              console.error('Error creating expense:', error);
              this.isSubmitting = false;
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  markFormFieldsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormFieldsAsTouched(control);
      }
    });
  }

  onSubmissionSuccess(): void {
    this.isSubmitting = false;
    this.expenseEdited.emit();
    this.matDialog.close({ success: true });
  }

  onSubmissionError(error: any): void {
    this.isSubmitting = false;
    console.error('Error submitting form:', error);
  }

  numericInputValidity: { [key: string]: boolean } = {
    quantity: true,
    expenseAmount: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
