<div
  class="main-content body-bg p-6 setting-wrapper empty-account-wrapper overflow-auto"
>
  <div class="account-wrapper" [formGroup]="accountForm">
    <div class="account-detail mt-4 m-auto w-100">
      <div class="bg-white p-3 mb-4">
        <h2 class="mat-subtitle-1 text fw-500" style="margin-bottom: 0">
          Account Details
        </h2>
        <span class="textXS grey-text d-block mb-6">Enter company details</span>
        <div class="upload-photo relative mb-6">
          <div class="text-center" *ngIf="!uploadedLogo; else logoUploaded">
            <input
              type="file"
              class="file-input pointer"
              (change)="onFileSelected($event)"
              accept="image/*"
              style="display: none"
              #fileInput
            />
            <div style="cursor: pointer" (click)="fileInput.click()">
              <mat-icon fontSet="material-icons-outlined"
                >cloud_upload</mat-icon
              >
              <span class="text-center text logo-info d-block textXS mb-1"
                >Upload Logo</span
              >
            </div>
            <div class="text-center textXS grey-text upload-grey-text">
              Allowed JPG & PNG. <br />Max size of 200Kb
            </div>
          </div>
          <ng-template #logoUploaded>
            <div class="text-center">
              <img
                [src]="uploadedLogo"
                alt="Uploaded Logo"
                style="
                  width: 188px;
                  cursor: pointer;
                  height: 103px;
                  max-height: 103px;
                  object-fit: contain;
                "
              />
              <input
                type="file"
                class="file-input pointer"
                (change)="onFileSelected($event)"
                accept="image/*"
                style="display: none"
                #fileInput
              />

              <sft-button
                [label]="'Update Logo'"
                (buttonClick)="openFileInput()"
              />
            </div>
          </ng-template>

          <p *ngIf="logoError" class="mt-2 text-center textXS required-star">
            {{ logoError }}
          </p>
        </div>
        <div class="border-line mb-6">
          <input formControlName="accountID" type="hidden" />
          <p class="mat-body-1 text mb-1 mt-6 input-label">
            Account Name <span class="required-star">*</span>
          </p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              appCapitalizeFirstLetter
              id="name"
              matInput
              placeholder="Enter here"
              formControlName="name"
            />
          </mat-form-field>
          <div
            *ngIf="
              accountForm.get('name')?.touched || accountForm.get('name')?.dirty
            "
          >
            <div
              *ngIf="accountForm.get('name')?.hasError('whitespace')"
              class="required-star mt-1"
            >
              Field cannot be empty or contain only whitespace.
            </div>
            <div
              *ngIf="
                !accountForm.get('name')?.hasError('whitespace') &&
                accountForm.get('name')?.hasError('invalidNameSpecialChar')
              "
              class="required-star mt-1"
            >
              Field cannot contain special characters.
            </div>
            <div
              *ngIf="
                !accountForm.get('name')?.hasError('whitespace') &&
                accountForm.get('name')?.hasError('invalidName')
              "
              class="required-star mt-1"
            >
              Invalid Name.
            </div>
          </div>
          <div formGroupName="address">
            <input formControlName="addressID" type="hidden" />
            <p class="mat-body-1 text mb-1 mt-6 input-label">
              Address Line 1 <span class="required-star">*</span>
            </p>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <input
                matInput
                placeholder="Enter here"
                formControlName="fullAddress"
              />
            </mat-form-field>
            <div
              class="required-star mt-1"
              *ngIf="
                accountForm
                  .get('address.fullAddress')
                  ?.hasError('whitespace') &&
                accountForm.get('address.fullAddress')?.touched
              "
            >
              Address Line 1 cannot be empty or contain only whitespace.
            </div>
            <span
              class="required-star"
              *ngIf="
                accountForm.get('address.fullAddress')?.invalid &&
                (accountForm.get('address.fullAddress')?.touched ||
                  accountForm.get('address.fullAddress')?.dirty)
              "
            >
              Field is required.
            </span>
            <div class="row">
              <div class="col mb-6 s12 m6 l3">
                <p class="mat-body-1 text mb-1 mt-6 input-label">
                  Country <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="country"
                    [matAutocomplete]="autoCountry"
                    (input)="onCountryInput($event)"
                  />
                </mat-form-field>
                <mat-autocomplete
                  #autoCountry="matAutocomplete"
                  (optionSelected)="onCountrySelected($event)"
                >
                  <mat-option
                    *ngFor="let country of filteredCountries"
                    [id]="country.countryID"
                    [value]="country.name"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-autocomplete>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('address.country')?.hasError('required') &&
                    (accountForm.get('address.country')?.touched ||
                      accountForm.get('address.country')?.dirty)
                  "
                >
                  Field is required.</span
                >
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('address.country')?.hasError('numeric')
                  "
                >
                  Country cannot contain numeric characters or symbols.
                </span>
              </div>
              <div class="col mb-6 s12 m6 l3">
                <p class="mat-body-1 text mb-1 mt-6 input-label">
                  State <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="state"
                    [matAutocomplete]="autoState"
                    (input)="onStateInput($event)"
                  />
                </mat-form-field>
                <mat-autocomplete
                  #autoState="matAutocomplete"
                  (optionSelected)="onStateSelected($event)"
                >
                  <mat-option
                    *ngFor="let state of filteredStates"
                    [id]="state.stateID"
                    [value]="state.name"
                  >
                    {{ state.name }}
                  </mat-option>
                </mat-autocomplete>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('address.state')?.hasError('required') &&
                    (accountForm.get('address.state')?.touched ||
                      accountForm.get('address.state')?.dirty)
                  "
                >
                  Field is required.</span
                >
              </div>
              <div class="col mb-6 s12 m6 l3">
                <p class="mat-body-1 text mb-1 mt-6 input-label input-text">
                  City <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="city"
                    [matAutocomplete]="autoCity"
                    (input)="onCityInput($event)"
                  />
                  <mat-autocomplete
                    #autoCity="matAutocomplete"
                    (optionSelected)="onCitySelected($event)"
                  >
                    <mat-option
                      *ngFor="let city of filteredCities"
                      [id]="city.cityID"
                      [value]="city.name"
                    >
                      {{ city.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('address.city')?.hasError('required') &&
                    (accountForm.get('address.city')?.touched ||
                      accountForm.get('address.city')?.dirty)
                  "
                >
                  Field is required.</span
                >
              </div>
              <div class="col s12 m6 l3">
                <p class="mat-body-1 text mb-1 mt-6 input-label input-text">
                  Zip Code <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="zipCode"
                    minlength="4"
                    maxlength="6"
                    appNumericInput
                    (numericInputValidityChange)="
                      handleNumericInputValidityChange('zipCode', $event)
                    "
                  />
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="!numericInputValidity['zipCode']"
                  >Please enter a numeric value.</span
                >
                <span
                  class="required-star"
                  *ngIf="accountForm.get('address.zipCode')?.errors?.['required'] && accountForm.get('address.zipCode')?.touched && numericInputValidity['zipCode']"
                >
                  Field is required.
                </span>
                <span
                  class="required-star"
                  *ngIf="accountForm.get('address.zipCode')?.errors?.['minlength'] || accountForm.get('address.zipCode')?.errors?.['maxlength'] && numericInputValidity['zipCode']"
                >
                  Zip Code is not valid.
                </span>
              </div>
            </div>
          </div>
          <div class="mb-6 timeZone-wrapper" formGroupName="address">
            <p class="mat-body-1 text mb-1 input-label">
              Time zone? <span class="required-star">*</span>
            </p>

            <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              aria-placeholder="Select"
            >
              <mat-select placeholder="Select" formControlName="timeZone">
                <mat-form-field
                  appearance="outline"
                  class="search-input pl-2 mt-2 mb-2"
                >
                  <input
                    matInput
                    [formControl]="searchControl"
                    (keyup)="filterTimeZones()"
                    placeholder="Search"
                  />
                  <mat-icon
                    style="cursor: pointer"
                    matSuffix
                    *ngIf="searchControl.value"
                    (click)="clearSearch()"
                    >cancel</mat-icon
                  >
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <mat-option
                  *ngFor="let timeZone of filteredTimeZones"
                  [value]="timeZone"
                >
                  {{ timeZone }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="border-line mb-6" formGroupName="contract">
          <span class="mat-subtitle-1 text fw-500">Contract Details</span>
          <span class="textXS grey-text d-block mb-6"
            >Please enter contract representative & validity details</span
          >
          <div class="row">
            <div class="col mb-6 s12 m6 l6">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label">
                  Contract Start Date <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="dd/mm/yyyy"
                    formControlName="startDate"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('contract.startDate')?.invalid &&
                    accountForm.get('contract.startDate')?.touched
                  "
                >
                  Field is required.</span
                >
              </div>
            </div>
            <div class="col mb-6 s12 m6 l6">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label">
                  Contract Review Date <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    [matDatepicker]="startpicker"
                    placeholder="dd/mm/yyyy"
                    formControlName="reviewDate"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="startpicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startpicker></mat-datepicker>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('contract.reviewDate')?.invalid &&
                    accountForm.get('contract.reviewDate')?.touched
                  "
                >
                  <span
                    *ngIf="accountForm.get('contract.reviewDate')?.errors?.['required']"
                    >Field is required.</span
                  >
                  <span
                    *ngIf="accountForm.get('contract.reviewDate')?.errors?.['reviewDateInvalid']"
                    >Review Date must be greater than Start Date.</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mb-6 s12 m6 l6">
              <input formControlName="contractID" type="hidden" />
              <p class="mat-body-1 text mb-1 input-label">
                Softobiz Account Manager <span class="required-star">*</span>
              </p>
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input
                  appCapitalizeFirstLetter
                  matInput
                  placeholder="Enter here"
                  formControlName="accountManager"
                />
              </mat-form-field>
              <div
                class="required-star mt-1"
                *ngIf="
                  accountForm
                    .get('contract.accountManager')
                    ?.hasError('whitespace') &&
                  accountForm.get('contract.accountManager')?.touched
                "
              >
                Softobiz Account Manager cannot be empty or contain only
                whitespace.
              </div>
              <span
                class="required-star"
                *ngIf="
                  accountForm.get('contract.accountManager')?.invalid &&
                  accountForm.get('contract.accountManager')?.touched
                "
              >
                Field is required.</span
              >
            </div>
            <div class="col mb-6 s12 m6 l6">
              <p class="mat-body-1 text mb-1 input-label">
                Client SPOC <span class="required-star">*</span>
              </p>
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input
                  appCapitalizeFirstLetter
                  matInput
                  placeholder="Enter here"
                  formControlName="clientSpoc"
                />
              </mat-form-field>
              <div
                class="required-star mt-1"
                *ngIf="
                  accountForm
                    .get('contract.clientSpoc')
                    ?.hasError('whitespace') &&
                  accountForm.get('contract.clientSpoc')?.touched
                "
              >
                Client SPOC cannot be empty or contain only whitespace.
              </div>
              <span
                class="required-star"
                *ngIf="
                  accountForm.get('contract.clientSpoc')?.invalid &&
                  accountForm.get('contract.clientSpoc')?.touched
                "
              >
                Field is required.</span
              >
            </div>
          </div>
        </div>
        <div formGroupName="financials">
          <span class="mat-subtitle-1 text fw-500">Financial Details</span>
          <span class="textXS grey-text d-block mb-6"
            >Please enter Account’s Finance representative details.</span
          >
          <input formControlName="financialID" type="hidden" />
          <div class="row financial-data">
            <div class="col s12 m6 l3 financial-info-col style">
              <p class="mat-body-1 text mb-1 input-label">
                Name <span class="required-star">*</span>
              </p>
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input
                  appCapitalizeFirstLetter
                  id="name"
                  matInput
                  placeholder="Enter here"
                  formControlName="name"
                />
              </mat-form-field>
              <div
                *ngIf="
                  accountForm.get('financials.name')?.touched ||
                  accountForm.get('financials.name')?.dirty
                "
              >
                <div
                  *ngIf="
                    accountForm.get('financials.name')?.hasError('whitespace')
                  "
                  class="required-star mt-1"
                >
                  Field cannot be empty or contain only whitespace.
                </div>
                <div
                  *ngIf="
                    !accountForm
                      .get('financials.name')
                      ?.hasError('whitespace') &&
                    accountForm
                      .get('financials.name')
                      ?.hasError('invalidNameSpecialChar')
                  "
                  class="required-star mt-1"
                >
                  Field cannot contain special characters.
                </div>
                <div
                  *ngIf="
                    !accountForm
                      .get('financials.name')
                      ?.hasError('whitespace') &&
                    accountForm.get('financials.name')?.hasError('invalidName')
                  "
                  class="required-star mt-1"
                >
                  Invalid Center Name.
                </div>
              </div>
              <div
                class="required-star mt-1"
                *ngIf="
                  accountForm.get('financials.name')?.hasError('whitespace') &&
                  accountForm.get('financials.name')?.touched
                "
              >
                Name cannot be empty or contain only whitespace.
              </div>
              <span
                class="required-star"
                *ngIf="
                  accountForm.get('financials.name')?.invalid &&
                  (accountForm.get('financials.name')?.touched ||
                    accountForm.get('financials.name')?.dirty)
                "
              >
                Field is required.
              </span>
            </div>
            <div class="col s12 m6 l3 financial-info-col style">
              <p class="mat-body-1 text mb-1 input-label" for="phone">
                Financial Contact Number <span class="required-star">*</span>
              </p>
              <div class="d-flex finance-detail-wrapper contact">
                <mat-form-field
                  appearance="outline"
                  subscriptSizing="dynamic"
                  class="country-select"
                >
                  <mat-select placeholder="Code" formControlName="countryCode">
                    <mat-option value="+91">+91</mat-option>
                    <mat-option value="+1">+1</mat-option>
                    <mat-option value="+61">+61</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    placeholder="Enter here"
                    formControlName="phone"
                    appNumericInput
                    minlength="6"
                    maxlength="10"
                    (numericInputValidityChange)="
                      handleNumericInputValidityChange('contactNumber', $event)
                    "
                  />
                </mat-form-field>
              </div>
              <span
                class="required-star"
                *ngIf="!numericInputValidity['contactNumber']"
                >Please enter a numeric value.</span
              >
              <span
                class="required-star"
                *ngIf="accountForm.get('financials.phone')?.errors?.['required'] && accountForm.get('financials.phone')?.touched && numericInputValidity['contactNumber']"
              >
                Field is required.
              </span>
            </div>
            <div class="col s12 m6 l3 financial-info-col">
              <p class="mat-body-1 text mb-1 input-label input-info">
                Financial Email Address <span class="required-star">*</span>
              </p>
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input
                  id="email"
                  matInput
                  placeholder="Enter here"
                  formControlName="email"
                />
              </mat-form-field>

              <span
                class="required-star"
                *ngIf="
                  accountForm.get('financials.email')?.invalid &&
                  accountForm.get('financials.email')?.touched
                "
              >
                <span
                  *ngIf="accountForm.get('financials.email')?.errors?.['required']"
                >
                  Field is required.
                </span>
                <span
                  *ngIf="accountForm.get('financials.email')?.errors?.['email']"
                >
                  Email is not valid.
                </span>
              </span>
            </div>
            <div class="col s12 m6 l3 financial-info-col">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label input-info">
                  Currency <span class="required-star">*</span>
                </p>
                <mat-form-field
                  appearance="outline"
                  subscriptSizing="dynamic"
                  aria-placeholder="select"
                  [style.cursor]="isEditMode ? 'not-allowed' : 'pointer'"
                >
                  <mat-select placeholder="Select" formControlName="currency">
                    <mat-option
                      *ngFor="let currency of filteredCurrencies"
                      [value]="currency.cr_currency"
                      >{{ currency.cr_currency }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('financials.currency')?.invalid &&
                    (accountForm.get('financials.currency')?.touched ||
                      accountForm.get('financials.currency')?.dirty)
                  "
                >
                  Field is required.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mt-6 s12 m6 l6">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label">
                  Billing Cycle <span class="required-star">*</span>
                </p>
                <mat-form-field
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [style.cursor]="isEditMode ? 'not-allowed' : 'pointer'"
                >
                  <input
                    matInput
                    [matDatepicker]="billingCyclePicker"
                    placeholder="dd/mm/yyyy"
                    formControlName="billingCycle"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="billingCyclePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #billingCyclePicker></mat-datepicker>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('financials.billingCycle')?.invalid &&
                    accountForm.get('financials.billingCycle')?.touched
                  "
                >
                  Field is required.</span
                >
              </div>
            </div>
            <div class="col mt-6 s12 m6 l6">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label">
                  Credit Term (in days) <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select
                    placeholder="Select credit term"
                    formControlName="creditTerm"
                  >
                    <mat-option value="15">15</mat-option>
                    <mat-option value="30">30</mat-option>
                    <mat-option value="45">45</mat-option>
                    <mat-option value="60">60</mat-option>
                  </mat-select>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    accountForm.get('financials.creditTerm')?.invalid &&
                    accountForm.get('financials.creditTerm')?.touched
                  "
                >
                  <span
                    *ngIf="accountForm.get('financials.creditTerm')?.errors?.['required']"
                  >
                    Field is required.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-end">
        <sft-button
          class="mr-2"
          [label]="'Cancel'"
          [color]="'primary'"
          [variant]="'stroked'"
          [disable]="false"
          [icon]="'home'"
          [disableRipple]="false"
          [size]="'medium'"
          (buttonClick)="onCancelClick()"
        ></sft-button>
        <sft-button
          [label]="isEditMode ? 'Update' : 'Save'"
          [color]="'primary'"
          [variant]="'flat'"
          [icon]="'home'"
          [size]="'medium'"
          (buttonClick)="onSaveClick()"
        ></sft-button>
      </div>
    </div>
  </div>
</div>
