import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { nameValidator } from 'src/app/utils/name-validation.utils';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-dialog-costcentre',
  templateUrl: './cost-center-dialog-account-detail.html',
})
export class CostCenterDialogComponent {
  costCentreForm!: FormGroup;
  isEditMode: boolean = false;
  accountList: any[] = [];
  selected = 'hourly';
  isButtonDisabled: boolean = true;
  isSubmitting: boolean = false;
  isNumericInputValid: boolean = true;

  constructor(
    public matDialog: MatDialogRef<CostCenterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public accountId: any,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private costCentreService: CostCenterService
  ) {}
  private unsubscribe: Subject<void> = new Subject();

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();
    if (this.isEditMode) {
      this.costCentreService
        .getCostListById(this.data.element.cc_cost_centerID)
        .subscribe((res: any) => {
          this.costCentreForm.patchValue({
            accountID: res.data.account.accountID,
            code: res.data.code,
            centerName: res.data.centerName,
            centerLeader: res.data.centerLeader,
            marginRate: res.data.marginRate,
            cost_centerID: res.data.cost_centerID,
            toggleRequired: false,
          });
          this.isEditMode = true;
        });
    }
    this.costCentreForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.costCentreForm.invalid || !this.costCentreForm.dirty;
    });

    this.isButtonDisabled = true;
  }

  initializeForm(): void {
    this.costCentreForm = this.formBuilder.group({
      code: ['', [Validators.required, whitespaceValidator()]],
      centerName: ['', [Validators.required, whitespaceValidator(), nameValidator]],
      centerLeader: ['', [Validators.required, whitespaceValidator()]],
      marginRate: ['', Validators.required],
      accountID: [''],
    });

    if (this.isEditMode) {
      this.costCentreForm.addControl(
        'cost_centerID',
        this.formBuilder.control('')
      );
    }
  }

  onSubmit() {
    if (this.costCentreForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      const costData = this.costCentreForm.value;

      if (this.isEditMode) {
        delete costData.accountID;
        this.costCentreService
          .updateCost(costData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => console.log('error', error),
          });
      } else {
        costData.accountID = this.data.accountID;
        this.costCentreService
          .createCost(costData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => console.log('error', error),
          });
      }
    } else {
      this.markFormFieldsAsTouched(this.costCentreForm);
    }
  }

  markFormFieldsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormFieldsAsTouched(control);
      }
    });
  }

  handleNumericInputValidityChange(validity: boolean) {
    this.isNumericInputValid = validity;
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
