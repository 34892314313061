import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  startWith,
  takeUntil,
  throwError,
} from 'rxjs';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/shared/components/snack-bar/snack-bar.component';
import {
  CropperDialogResult,
  LogoCropperComponent,
} from '../logo-cropper/logo-cropper.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environment/environment';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';
import { nameValidator } from 'src/app/utils/name-validation.utils';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
})
export class CreateAccountComponent implements OnInit {
  baseUrl = environment.apiUrl;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  selectedCountry: string = '';
  filteredCountries: any[] = [];
  filteredStates: any[] = [];
  filteredCities: any[] = [];
  selectedCountryId!: number;
  selectedStateId!: number;
  selectedCityId!: number;
  accountForm!: FormGroup;
  uploadedLogo: string | undefined;
  getLogo: string | undefined = '';
  contractReviewDate: Date | undefined;
  country: any;
  timeZones: any[] = [];
  selectedFile: File | null = null;
  accountId!: string;
  currencies: any[] = [];
  filteredCurrencies: any[] = [];
  currencyControl = new FormControl();
  isEditMode: boolean = false;
  logoError: string | null = null;
  compareSelectedCountryId: any;
  phoneNumberValid: boolean = true;
  currentState: any;
  isButtonDisabled: boolean = true;
  selectedState: any;

  filteredTimeZones: string[] = [];
  searchControl = new FormControl('');

  private destroy$ = new Subject<void>();
  private globalServiceSubscription: Subscription | undefined;
  durationInSeconds = 50000;
  userID!: string;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) { }

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('autoCountry') autoCountry!: MatAutocomplete;
  @ViewChild('autoState') autoState!: MatAutocomplete;
  @ViewChild('autoCity') autoCity!: MatAutocomplete;

  ngOnInit(): void {
    this.initializeForm();
    this.loadCountries();
    this.loadCurrencies();
    this.subscribeToCurrencyControl();
    this.subscribeToRouteParams();
    this.subscribeToCountryChanges();
    this.loadTimeZones();
    if (this.isEditMode) {
      this.compareCountry();
      this.compareState();
    }
    this.getCurrentState();
    const user = JSON.parse(localStorage.getItem('user') || '');
    this.userID = user.userID;

    this.accountForm.valueChanges.subscribe(() => {
      this.checkIfFormIsDirtyOrInvalid();
    });

    this.checkIfFormIsDirtyOrInvalid();
  }

  checkIfFormIsDirtyOrInvalid(): void {
    this.isButtonDisabled = this.accountForm.invalid || !this.accountForm.dirty;
  }

  subscribeToCountryChanges(): void {
    this.accountForm
      .get('country')
      ?.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        map((value) => this.filterCountries(value))
      )
      .subscribe((filteredCountries) => {
        this.filteredCountries = filteredCountries;
      });
  }

  getCurrentState() {
    this.route.params.subscribe((params) => {
      const accountId = params['id'];
      if (accountId) {
        this.accountId = accountId;
        this.isEditMode = true;
        this.fetchAccountDetails(accountId)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: (accountDetails: any) => {
              this.currentState = accountDetails.data.address[0].state;
            },
            error: (error: any) => {
              console.error('Error fetching account details:', error);
            },
          });
      }
    });
  }

  subscribeToCurrencyControl(): void {
    this.currencyControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.globalService.componentDestroyed(this))
      )
      .subscribe((searchTerm: string) => {
        this.filteredCurrencies = this.filterCurrencies(searchTerm);
      });
  }

  subscribeToRouteParams(): void {
    this.route.params.subscribe((params) => {
      const accountId = params['id'];
      if (accountId) {
        this.isEditMode = true;
        this.fetchAccountDetails(accountId)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: (accountDetails: any) => {
              this.patchFormWithAccountDetails(accountDetails?.data);
            },
            error: (error: any) => {
              console.error('Error fetching account details:', error);
            },
          });
      }
    });
  }

  reviewDateValidator(startDateControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const reviewDate = control.value;
      const startDate = control.parent?.get(startDateControlName)?.value;

      if (startDate && reviewDate && reviewDate < startDate) {
        return { reviewDateInvalid: true };
      }

      return null;
    };
  }

  initializeForm(): void {
    this.accountForm = this.fb.group({
      logo: [''],
      accountID: [''],
      name: ['', [Validators.required, whitespaceValidator(), nameValidator]],

      contract: this.fb.group({
        contractID: [''],
        startDate: ['', Validators.required],
        reviewDate: [
          '',
          [Validators.required, this.reviewDateValidator('startDate')],
        ],
        accountManager: ['', [Validators.required, whitespaceValidator()]],
        clientSpoc: ['', [Validators.required, whitespaceValidator()]],
      }),

      address: this.fb.group({
        addressID: [''],
        country: ['', [Validators.required, this.noNumericValidator()]],
        state: ['', [Validators.required]],
        city: ['', [Validators.required]],
        zipCode: ['', [Validators.required, Validators.maxLength(6)]],
        fullAddress: ['', [Validators.required, whitespaceValidator()]],
        timeZone: ['', Validators.required],
      }),

      financials: this.fb.group({
        financialID: [''],
        name: ['', [Validators.required, whitespaceValidator(), nameValidator]],
        email: ['', [Validators.email, Validators.required]],
        countryCode: ['', Validators.required],
        phone: ['', Validators.required],
        currency: ['', Validators.required],
        billingCycle: ['', Validators.required],
        creditTerm: ['', Validators.required],
      }),
    });
    this.searchControl.valueChanges.subscribe(() => {
      this.filterTimeZones();
    });
  }

  noNumericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
        control.value
      );
      return forbidden ? { numeric: { value: control.value } } : null;
    };
  }

  fetchAccountDetails(accountId: string): Observable<any> {
    return this.accountService.getAccountById(accountId);
  }

  patchFormWithAccountDetails(accountDetails: any): void {
    if (accountDetails.logo) {
      this.uploadedLogo = `${this.baseUrl}/files/${accountDetails.logo}`;
    }

    try {
      // Clear previous form data
      this.accountForm.reset();

      if (accountDetails.logo) {
        this.uploadedLogo = `${this.baseUrl}/files/${accountDetails.logo}`;
      }

      if (accountDetails.contracts && accountDetails.contracts.length > 0) {
        const contract = accountDetails.contracts[0];
        this.accountForm.patchValue({
          accountID: accountDetails?.accountID,
          name: accountDetails?.name,
          logo: accountDetails.logo,
          contract: {
            contractID: contract.contractID || '',
            startDate: contract.startDate || '',
            reviewDate: contract.reviewDate || '',
            accountManager: contract.accountManager || '',
            clientSpoc: contract.clientSpoc || '',
          },
        });
      } else {
        console.warn('Contracts data is missing or empty.');
      }

      if (accountDetails.address && accountDetails.address.length > 0) {
        const address = accountDetails.address[0];
        this.accountForm.patchValue({
          address: {
            addressID: address.addressID || '',
            city: address.city || '',
            state: address.state || '',
            country: address.country || '',
            zipCode: address.zipCode || '',
            fullAddress: address.fullAddress || '',
            timeZone: address.timeZone || '',
          },
        });
      } else {
        console.warn('Address data is missing or empty.');
      }

      if (accountDetails.financials && accountDetails.financials.length > 0) {
        const financial = accountDetails.financials[0];
        this.accountForm.patchValue({
          financials: {
            financialID: financial.financialID || '',
            name: financial.name || '',
            email: financial.email || '',
            countryCode: financial.countryCode || '',
            phone: financial.phone || '',
            currency: financial.currency || '',
            billingCycle: financial.billingCycle || '',
            creditTerm: financial.creditTerm || '',
          },
        });
        this.accountForm.get('financials.currency')?.disable();
        this.accountForm.get('financials.billingCycle')?.disable();
      } else {
        console.warn('Financials data is missing or empty.');
      }
    } catch (error) {
      console.error(
        'An error occurred while patching form with account details:',
        error
      );
    }
  }

  loadTimeZones(): void {
    this.accountService.getTimeZone().subscribe({
      next: (data: any) => {
        this.timeZones = data.data.records;
        this.filteredTimeZones = this.timeZones;
      },
      error: (error: any) => {
        console.error('Error loading time zones:', error);
      },
    });
  }

  filterTimeZones(): void {
    const searchQuery = this.searchControl.value?.toString() ?? '';
    this.filteredTimeZones = this.timeZones.filter((timeZone) =>
      timeZone.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.filterTimeZones();
  }

  private filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    if (Array.isArray(this.countries)) {
      return this.countries
        .filter((country) => country.name)
        .filter((country) => country.name.toLowerCase().includes(filterValue));
    } else {
      return [];
    }
  }

  private updateAccount(): void {
    this.isEditMode = true;
    const updatedData = this.accountForm.getRawValue();
    updatedData.contract.contractID = this.accountForm.get(
      'contract.contractID'
    )?.value;
    updatedData.address.addressID =
      this.accountForm.get('address.addressID')?.value;
    updatedData.financials.financialID = this.accountForm.get(
      'financials.financialID'
    )?.value;
    updatedData.accountID = this.accountForm.get('accountID')?.value;
    this.accountService
      .updateAccount(updatedData)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          const accountId = response?.data?.accountID;
          this.router.navigate(['/account/view', accountId]);
          this.openUpdateSnackBar('Account updated successfully');
        },
        error: (error: any) => {
          if (error.status === 404) {
            console.error('Account not found. Handle this case appropriately.');
          } else {
            console.error('Error updating account:', error);
            this.openSnackBar('Error updating account. Please try again.');
          }
        },
      });
  }

  private createAccount(formData: any): void {
    formData.userID = this.userID;
    this.accountService
      .createAccount(formData)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          const accountId = response?.data?.accountID;
          if (accountId) {
            this.router.navigate(['/account/view', accountId]);
            this.openUpdateSnackBar('Account created successfully');
          } else {
            console.error('Error: No account ID returned in the response.');
            this.openSnackBar('Error creating account. Please try again.');
          }
        },
        error: (error: any) => {
          console.error('Error saving form data:', error);

          let errorMessage = 'Error creating account. Please try again.';

          if (error.error?.message) {
            errorMessage = error.error.message;
          } else if (error.status === 409) {
            errorMessage =
              'Account name already exists. Please choose a different name.';
          } else {
            errorMessage = error.message || errorMessage;
          }

          this.openErrorSnackBar(errorMessage);
        },
      });
  }

  onCountrySelected(event: any): void {
    this.compareCountry();
    this.compareState();
    this.selectedCountryId = event.option.id;
    this.loadStates(this.selectedCountryId);
  }

  loadCountries(): void {
    this.accountService
      .getCountries()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.countries = data.data.records;
        },
        error: (error: any) => {
          console.error('Error in getCountries request:', error);
        },
      });
  }

  onCountryInput(event: any): void {
    const searchText: string = event.target.value.toLowerCase();
    this.filteredCountries = this.countries
      ? this.countries.filter((country) =>
        country.name.toLowerCase().includes(searchText)
      )
      : [];

    // If only one country is found, load its states
    if (this.filteredCountries.length === 1) {
      const selectedCountryId = this.filteredCountries[0].id;
      this.loadStates(selectedCountryId);
    }
  }

  compareCountry(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.accountService
        .getCountries()
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: (data: any) => {
            this.countries = data.data.records;
            const selectedCountry = this.countries.find(
              (country) =>
                country.name.toLowerCase() ===
                this.accountForm.value.address.country.toLowerCase()
            );
            if (selectedCountry) {
              this.compareSelectedCountryId = selectedCountry.countryID;
              resolve(this.compareSelectedCountryId);
            } else {
              reject('Country not found');
            }
          },
          error: (err) => reject(err),
        });
    });
  }

  async compareState() {
    try {
      const countryId = await this.compareCountry();
      const states = await firstValueFrom(this.loadStates(countryId));

      const State = states.find(
        (state: any) => state.name === this.accountForm.value.address.state
      );
      this.selectedState = State?.stateID;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // States
  onStateSelected(event: any): void {
    const selectedStateName = event.option.value;
    if (this.currentState !== selectedStateName) {
      this.accountForm.patchValue({
        address: {
          city: '',
          zipCode: '',
        },
      });
    }
    this.selectedStateId = event.option.id;
    this.loadCities(this.selectedStateId);
  }

  onStateInput(event: any): void {
    if (this.compareSelectedCountryId) {
      this.loadStates(this.compareSelectedCountryId);
    }
    const searchText: string = event.target.value.toLowerCase();
    this.filteredStates = this.states
      ? this.states.filter((state) =>
        state.name.toLowerCase().includes(searchText)
      )
      : [];
    // If filteredStates is empty, load the states based on the input value
    if (this.filteredStates.length === 0) {
      if (this.selectedCountryId) {
        this.loadStates(this.selectedCountryId);
      }
    }
  }

  loadStates(countryId: number) {
    return this.accountService.getStates(countryId).pipe(
      takeUntil(this.globalService.componentDestroyed(this)),
      map((data: any) => {
        if (data && data.data && data.data.records) {
          this.states = data.data.records;
          return this.states;
        } else {
          throw new Error('Invalid data structure');
        }
      }),
      catchError((error) => {
        console.error('Error in getStates request:', error);
        return throwError(() => error);
      })
    );
  }

  //  Cities
  loadCities(stateId: number | undefined): void {
    if (!stateId) {
      console.error('State ID is undefined. Cannot load cities.');
      return;
    }

    this.accountService
      .getCities(stateId)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.cities = data.data.records;
        },
        error: (error: any) => {
          console.error('Error in getCities request:', error);
        },
      });
  }

  onCityInput(event: any): void {
    this.loadCities(this.selectedState);
    const searchText: string = event.target.value.toLowerCase();
    this.filteredCities = this.cities
      ? this.cities.filter((city) =>
        city.name.toLowerCase().includes(searchText)
      )
      : [];
  }

  onCitySelected(event: any): void {
    this.selectedCityId = event.option.id;
  }

  onSaveClick(): void {
    if (this.accountForm.valid) {
      const formData = this.accountForm.value;
      if (formData.accountID && this.isEditMode) {
        this.updateAccount();
      } else {
        this.createAccount(formData);
      }
      this.markFormGroupTouched(this.accountForm);
    } else {
      this.markFormGroupTouched(this.accountForm);
      const emptyFields = this.getEmptyRequiredFields();
      if (emptyFields.length > 0) {
        const message =
          emptyFields.length === 1
            ? `${emptyFields[0]} is required.`
            : 'Please fill out all required fields.';
        this.openSnackBar(message);
      }
    }
  }

  onCancelClick() {
    this.router.navigate(['/account']);
  }

  getEmptyRequiredFields(): string[] {
    const emptyRequiredFields: string[] = [];
    const formControls = this.accountForm.controls;
    for (const controlName in formControls) {
      if (formControls.hasOwnProperty(controlName)) {
        const control = formControls[controlName];
        if (control instanceof FormControl && control.errors?.['required']) {
          emptyRequiredFields.push(controlName);
        } else if (control instanceof FormGroup) {
          const nestedEmptyFields =
            this.getEmptyRequiredFieldsFromGroup(control);
          emptyRequiredFields.push(...nestedEmptyFields);
        }
      }
    }
    return emptyRequiredFields;
  }

  getEmptyRequiredFieldsFromGroup(group: FormGroup): string[] {
    const emptyRequiredFields: string[] = [];
    const formControls = group.controls;
    for (const controlName in formControls) {
      if (formControls.hasOwnProperty(controlName)) {
        const control = formControls[controlName];
        if (control instanceof FormControl && control.errors?.['required']) {
          emptyRequiredFields.push(controlName);
        } else if (control instanceof FormGroup) {
          const nestedEmptyFields =
            this.getEmptyRequiredFieldsFromGroup(control);
          emptyRequiredFields.push(...nestedEmptyFields);
        }
      }
    }
    return emptyRequiredFields;
  }

  openSnackBar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = 'top';
    config.panelClass = 'warning-snackbar';
    config.data = { message: message };

    this.snackBar.openFromComponent(SnackBarComponent, config);
  }

  openErrorSnackBar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = 'top';
    config.panelClass = 'error-snackbar';
    config.data = { message: message };

    this.snackBar.openFromComponent(SnackBarComponent, config);
  }

  openUpdateSnackBar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 2000;
    config.verticalPosition = 'top';
    config.panelClass = 'success-snackbar';
    config.data = { message: message };

    this.snackBar.openFromComponent(SnackBarComponent, config);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  openFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected(event: any): void {
    const file = event.target?.files[0];
    if (file) {
      if (this.validateFile(file)) {
        const dialogRef = this.dialog.open(LogoCropperComponent, {
          data: {
            image: file,
            width: 400,
            height: 400,
          },
          width: '500px',
        });
        dialogRef
          .afterClosed()
          .pipe(filter((result) => !!result))
          .subscribe((result: CropperDialogResult) => {
            this.uploadLogoToServer(result.blob);
            this.uploadedLogo = result.imageUrl;
          });
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  validateFile(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 200 * 1024; // 200KB

    if (!allowedTypes.includes(file.type)) {
      this.logoError = 'Logo must be JPG or PNG.';
      return false;
    }

    if (file.size > maxSize) {
      this.logoError = 'Logo size must be less than 200KB.';
      return false;
    }

    this.logoError = null;
    return true;
  }

  uploadLogoToServer(blob: Blob): void {
    const file = new File([blob], 'filename.png', { type: 'image/png' });
    if (file !== null) {
      this.accountService
        .uploadFile(file)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: (response: any) => {
            this.accountForm.get('logo')?.setValue(response.id);
            this.logoError = null;
            this.markFormAsDirty();
          },
          error: (error: any) => {
            console.error('Error uploading logo:', error);
          },
        });
    }
  }

  markFormAsDirty() {
    this.accountForm.markAsDirty();
    this.checkIfFormIsDirtyOrInvalid();
  }

  loadCurrencies(): void {
    this.accountService
      .getCurrency()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.currencies = data.data.records;
          this.filteredCurrencies = this.currencies;
        },
        error: (error: any) => {
          console.error('Error in getCurrencies request:', error);
        },
      });
  }

  filterCurrencies(searchTerm: string): any[] {
    if (!searchTerm) {
      return this.currencies;
    }
    return this.currencies.filter((currency) =>
      currency.currency.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  numericInputValidity: { [key: string]: boolean } = {
    zipCode: true,
    contactNumber: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.globalServiceSubscription) {
      this.globalServiceSubscription.unsubscribe();
    }
  }
}
