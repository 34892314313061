<div class="modal-wrapper p-6" [formGroup]="costCentreForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Cost Center" : "Add Cost Center" }}
      </h2>
      <p class="textXS mt-2 text">Enter cost center details</p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Cost Center Code <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input matInput placeholder="Enter here" formControlName="code" />
      </mat-form-field>
      <div
        class="required-star mt-1"
        *ngIf="!costCentreForm.get('code')?.hasError('required') && costCentreForm.get('code')?.hasError('whitespace')"
      >
        Field cannot be empty or contain only whitespace.
      </div>
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Cost Center Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="centerName"
        />
      </mat-form-field>
      <div
        *ngIf="costCentreForm.get('centerName')?.touched || costCentreForm.get('centerName')?.dirty"
      >
        <div
          *ngIf="costCentreForm.get('centerName')?.hasError('whitespace')"
          class="required-star mt-1"
        >
          Field cannot be empty or contain only whitespace.
        </div>
        <div
          *ngIf="!costCentreForm.get('centerName')?.hasError('whitespace') && costCentreForm.get('centerName')?.hasError('invalidNameSpecialChar')"
          class="required-star mt-1"
        >
          Field cannot contain special characters.
        </div>
        <div
          *ngIf="!costCentreForm.get('centerName')?.hasError('whitespace') && costCentreForm.get('centerName')?.hasError('invalidName')"
          class="required-star mt-1"
        >
          Invalid Center Name.
        </div>
      </div>
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Cost Center Leader <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="centerLeader"
        />
      </mat-form-field>
      <div
        *ngIf="costCentreForm.get('centerLeader')?.touched || costCentreForm.get('centerLeader')?.dirty"
      >
        <span
          class="required-star"
          *ngIf="costCentreForm.get('centerLeader')?.hasError('required')"
        >
          Field is required.
        </span>
        <div
          class="required-star mt-1"
          *ngIf="!costCentreForm.get('centerLeader')?.hasError('required') && costCentreForm.get('centerLeader')?.hasError('whitespace')"
        >
          Field cannot be empty or contain only whitespace.
        </div>
      </div>
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Margin Rate (%) <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          matInput
          placeholder="Enter here"
          formControlName="marginRate"
          appNumericInput
          (numericInputValidityChange)="handleNumericInputValidityChange($event)"
        />
      </mat-form-field>
      <span class="required-star" *ngIf="!isNumericInputValid"
        >Please enter a numeric value.</span
      >
      <span
        class="required-star"
        *ngIf="
          costCentreForm.get('marginRate')?.invalid &&
          (costCentreForm.get('marginRate')?.touched ||
            costCentreForm.get('marginRate')?.dirty) && isNumericInputValid
        "
        >Field is required.</span
      >
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      class="mr-3 header-button"
      [label]="'Cancel'"
      [color]="'primary'"
      [variant]="'stroked'"
      [size]="'large'"
      (click)="close()"
    ></sft-button>
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSubmit()"
    ></sft-button>
  </div>
</div>
