import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable, takeUntil, tap } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { nameValidator } from 'src/app/utils/name-validation.utils';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-add-project-accounts-detail',
  templateUrl: './project-dialog-account-detail.component.html',
})
export class ProjectDialogAccountsDetailComponent implements OnInit {
  isEditMode: boolean = false;
  projectForm!: FormGroup;
  isSubmitting: boolean = false;
  costList: any[] = [];
  isButtonDisabled: boolean = true;
  currency: string | undefined;
  addedResources: string[] = [];
  isNumericInputValid: boolean = true;
  isMilestoneBased: boolean = false;

  constructor(
    public matDialog: MatDialogRef<ProjectDialogAccountsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private globalService: GlobalService,
    private costCenterService: CostCenterService
  ) { }

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '560px';
    this.matDialog.updateSize(dialogConfig.width);

    if (this.isEditMode && this.data.element) {
      this.patchForm(this.data.element);
    }

    this.initializeForm();

    if (this.isEditMode && this.data.element) {
      this.loadProjectDetails(this.data.element.projectId);
    }

    this.loadCostCenters().subscribe();

    this.currency = this.globalService.getCurrency();

    this.projectForm.valueChanges.subscribe(() => {
      this.updateButtonState();
    });

    this.updateButtonState();
  }

  loadProjectDetails(projectId: string) {
    this.projectService.getProjectById(projectId).subscribe((res: any) => {
      if (res && res.data) {
        const projectFormValue: any = {
          name: res.data.name,
          costModal: res.data.costModal,
          fixedCost: this.isMilestoneBased ? '0' : String(res.data.fixedCost),
          status: res.data.status,
          startDate: res.data.startDate,
          endDate: res.data.endDate,
          cost_centerID: res.data.costCenter?.cost_centerID,
          accountID: res.data.account?.accountID,
        };

        this.projectForm.patchValue(projectFormValue);
        this.projectForm.get('projectId')?.setValue(res.data.projectId);
        this.isMilestoneBased = res.data.mileStones?.length > 0;

        this.patchMilestones(res.data.mileStones || []);
      }
    });
  }

  patchMilestones(milestones: any[]) {
    const milestonesFormArray = this.milestonesFormArray;
    if (milestonesFormArray) {
      milestonesFormArray.clear();
      milestones.forEach((milestone) => {
        milestonesFormArray.push(
          this.formBuilder.group({
            mileStoneId: [milestone.mileStoneId],
            mileStoneName: [milestone.mileStoneName, Validators.required],
            price: [milestone.invoice?.invoiceItems[0].rate || milestone.price, [Validators.required]],
            dueDate: [milestone.dueDate, Validators.required],
          })
        );
      });
    }
  }

  endDateValidator(startDateControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const endDate = control.value;
      const startDate = control.parent?.get(startDateControlName)?.value;

      if (startDate && endDate && endDate < startDate) {
        return { reviewDateInvalid: true };
      }
      return null;
    };
  }

  toggleMilestoneBased() {
    if (this.isMilestoneBased) {
      if (this.isEditMode) {
        const milestonesArray = this.milestonesFormArray;

        if (milestonesArray && milestonesArray.length > 0) {
          if (milestonesArray.length === 1) {
            const milestoneId = milestonesArray.at(0).get('mileStoneId')?.value;

            // Call the delete API for the last milestone
            this.projectService.deleteMileStones(milestoneId).subscribe({
              next: () => {
                this.clearMilestones();
                this.isMilestoneBased = false;
                this.updateButtonState();
              },
              error: (error: any) => {
                console.error('Error deleting milestone:', error);
                this.clearMilestones();
                this.isMilestoneBased = false;
              },
            });
          } else {
            this.clearMilestones();
            this.isMilestoneBased = false;
          }
        } else {
          this.clearMilestones();
          this.isMilestoneBased = false;
        }
      } else {
        this.clearMilestones();
        this.isMilestoneBased = false;
      }
    } else {
      this.isMilestoneBased = true;
      if (!this.milestonesFormArray?.length) {
        this.addMilestone();
      }
    }

    this.milestonesFormArray?.controls.forEach((control: AbstractControl) => {
      control.get('mileStoneName')?.updateValueAndValidity();
      control.get('price')?.updateValueAndValidity();
      control.get('dueDate')?.updateValueAndValidity();
    });

    this.updateButtonState();
  }

  clearMilestones(): void {
    const milestonesArray = this.milestonesFormArray;
    if (milestonesArray) {
      milestonesArray.clear();
    }
    this.projectForm.markAsDirty();
  }

  get milestonesFormArray(): FormArray | null {
    return this.projectForm.get('milestones') as FormArray | null;
  }

  addMilestone(): void {
    const milestonesArray = this.milestonesFormArray;
    if (milestonesArray) {
      milestonesArray.push(this.createMilestoneFormGroup());
      this.projectForm.markAsDirty();
    }
  }

  removeMilestone(index: number): void {
    const milestonesArray = this.milestonesFormArray;
    if (milestonesArray && milestonesArray.length > index) {
      const milestoneId = milestonesArray.at(index).get('mileStoneId')?.value;
      this.projectService.deleteMileStones(milestoneId).subscribe({
        next: () => {
          milestonesArray.removeAt(index);
          this.projectForm.markAsDirty();
          this.updateButtonState();
        },
        error: (error: any) => {
          console.error('Error deleting milestone:', error);
        },
      });
    }
  }

  createMilestoneFormGroup(milestoneId: string = ''): FormGroup {
    const milestoneFormGroup = this.formBuilder.group({
      mileStoneId: [milestoneId],
      mileStoneName: ['', Validators.required],
      price: ['', Validators.required],
      dueDate: ['', Validators.required],
    });

    this.projectForm.get('fixedCost')?.valueChanges.subscribe(() => {
      milestoneFormGroup.get('price')?.updateValueAndValidity();
    });

    milestoneFormGroup.valueChanges.subscribe(() => {
      this.projectForm.markAsDirty();
      this.updateButtonState();
    });

    return milestoneFormGroup;
  }

  initializeForm(initialType: string = 'fixed'): void {
    this.projectForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator(), nameValidator]],
      costModal: [initialType, Validators.required],
      fixedCost: [''],
      status: ['active', Validators.required],
      startDate: ['', Validators.required],
      endDate: [null, [this.endDateValidator('startDate')]],
      cost_centerID: ['', Validators.required],
      projectId: [''],
      milestones: this.formBuilder.array([]),
    });

    if (this.isEditMode) {
      this.projectForm.addControl('projectId', this.formBuilder.control(''));
    }
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListByAccountId(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        this.data.accountID
      )
      .pipe(
        tap((response: any) => {
          this.costList = response.data.records;
        })
      );
  }

  onTypeChange() {
    this.projectForm.value;
  }

  patchForm(element: any): void {
    if (this.isEditMode) {
      this.projectService
        .getProjectById(this.data.element.projectId)
        .subscribe((res: any) => {
          this.projectForm.patchValue({
            name: res.data.name,
            costModal: res.data.costModal,
            fixedCost: res.data.fixedCost,
            projectId: res.data.projectId,
            startDate: res.data.startDate,
            status: this.data.element.status,
            endDate: res.data.endDate,
            cost_centerID: element.cost_centerID,
          });

          const milestonesArray = res.data.mileStones.map((milestone: any) =>
            this.formBuilder.group({
              mileStoneId: [milestone.mileStoneId],
              mileStoneName: [milestone.mileStoneName, Validators.required],
              price: [milestone.invoice?.invoiceItems[0].rate || milestone.price, Validators.required],
              dueDate: [new Date(milestone.dueDate), Validators.required],
            })
          );
          this.isMilestoneBased = milestonesArray.length > 0;
          this.projectForm.get('costModal')?.disable();

          this.projectForm.setControl(
            'milestones',
            this.formBuilder.array(milestonesArray)
          );
        });
    }
  }

  updateButtonState() {
    this.isButtonDisabled = !this.projectForm.valid || !this.projectForm.dirty;
  }

  onSubmit(): void {
    const projectData = this.projectForm.value;

    if (this.projectForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      // Temporarily enable the costModal control if it's disabled
      const costModalControl = this.projectForm.get('costModal');
      if (costModalControl?.disabled) {
        costModalControl.enable();
      }

      const { name, costModal, fixedCost, status, startDate, endDate } = this.projectForm.value;
      const projectId = this.projectForm.get('projectId')?.value;
      const accountID = this.data.accountID;

      // Determine if it's milestone based
      const isMilestoneBased = costModal === 'fixed' && this.isMilestoneBased;

      let projectPayload: any = {
        name,
        costModal,
        status,
        startDate,
        endDate,
        accountID,
        projectId,
        isMileStone: isMilestoneBased,
      };

      if (costModal === 'fixed') {
        const milestones = projectData.milestones.map((milestone: any) => ({
          ...milestone,
          mileStoneId: milestone.mileStoneId || null,
        }));

        projectPayload = {
          ...projectPayload,
          fixedCost: isMilestoneBased ? '0' : String(fixedCost),
          mileStones: milestones,
        };
      }

      // Check if cost center is selected
      const cost_centerID = this.projectForm.get('cost_centerID')?.value;
      if (cost_centerID) {
        projectPayload = {
          ...projectPayload,
          cost_centerID,
        };
      }

      // Handle submission (create or update)
      const request$ = this.isEditMode
        ? this.projectService.updateProject({ ...projectPayload, projectId })
        : this.projectService.createProject(projectPayload);

      request$
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.isSubmitting = false;
            this.matDialog.close({ success: true });
          },
          error: (error: any) => {
            console.error(`Error ${this.isEditMode ? 'updating' : 'creating'} project:`, error);
            this.isSubmitting = false;
          },
        });

      // Re-disable the costModal control after submission
      if (this.isEditMode) {
        costModalControl?.disable();
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  numericInputValidity: { [key: string]: boolean } = {
    fixedCost: true,
    price: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  onNoClick(): void {
    this.matDialog.close();
  }
}
